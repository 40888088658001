import ShortcutButton from "./Components/ShortcutButton";
import { SHORTCUTS_KEYS } from "sf/consts/keysAndMouse";

type ListItem = {
  shortcutTitle: string;
  title?: boolean;
  subTitle?: boolean;
  keyFirst?: () => JSX.Element;
  keySecond?: () => JSX.Element;
  keyBtn?: Array<typeof SHORTCUTS_KEYS>;
  divider?: string;
  id: string;
};

const SHORTCUTS_ITEMS_LIST_GENERAL: ListItem[] = [
  {
    shortcutTitle: "General",
    title: true,
    id: "general_0",
  },
  {
    shortcutTitle: "Pan tool",
    keySecond: () => <ShortcutButton title="Q" type="filled" />,
    keyBtn: SHORTCUTS_KEYS.PAN_TOOL,
    id: "general_1",
  },
  {
    shortcutTitle: "Pan tool (Temporary)",
    keyFirst: () => <ShortcutButton title="Hold" type="outlined" />,
    keySecond: () => <ShortcutButton title="Space" type="filled" />,
    keyBtn: SHORTCUTS_KEYS.PAN_TOOL_TEMPORARY,
    id: "general_2",
  },
  {
    shortcutTitle: "Center view",
    keySecond: () => <ShortcutButton title="F" type="filled" />,
    keyBtn: SHORTCUTS_KEYS.CENTER_VIEW,
    id: "general_3",
  },
  {
    shortcutTitle: "Undo",
    keyFirst: () => <ShortcutButton title="Ctrl" type="filled" />,
    keySecond: () => <ShortcutButton title="Z" type="filled" />,
    keyBtn: SHORTCUTS_KEYS.UNDO,
    id: "general_4",
  },
];

const SHORTCUTS_ITEMS_LIST_DRAWING: ListItem[] = [
  {
    shortcutTitle: "Drawing",
    title: true,
    id: "drawing_0",
  },
  {
    shortcutTitle: "Draw",
    keySecond: () => (
      <ShortcutButton title="Double click classification" type="outlined" />
    ),
    id: "drawing_1",
  },
  {
    shortcutTitle: "Draw with last classification",
    keySecond: () => <ShortcutButton title="L" type="filled" />,
    keyBtn: SHORTCUTS_KEYS.DRAW_WITH_LAST_CLASS,
    id: "drawing_2",
  },
  {
    shortcutTitle: "While drawing...",
    subTitle: true,
    id: "drawing_3",
  },
  {
    shortcutTitle: "Draw unconstrained",
    keyFirst: () => <ShortcutButton title="Hold" type="outlined" />,
    keySecond: () => <ShortcutButton title="Shift" type="filled" />,
    keyBtn: SHORTCUTS_KEYS.DRAW_UNCONSTRAINED,
    id: "drawing_4",
  },
  {
    shortcutTitle: "Exit drawing mode",
    keySecond: () => <ShortcutButton title="Esc" type="filled" />,
    keyBtn: SHORTCUTS_KEYS.EXIT_DRAWING_MODE,
    id: "drawing_5",
  },
  {
    shortcutTitle: "While drawing areas...",
    subTitle: true,
    id: "drawing_6",
  },
  {
    shortcutTitle: "Close polygon",
    keyFirst: () => <ShortcutButton title="Double click" type="outlined" />,
    keySecond: () => <ShortcutButton title="Enter" type="filled" />,
    keyBtn: SHORTCUTS_KEYS.CLOSE_POLYGON,
    divider: "or",
    id: "drawing_7",
  },
  {
    shortcutTitle: "Rectangle mode (Temporary)",
    keyFirst: () => <ShortcutButton title="Hold" type="outlined" />,
    keySecond: () => <ShortcutButton title="Ctrl" type="filled" />,
    keyBtn: SHORTCUTS_KEYS.RECTANGLE_MODE,
    id: "drawing_8",
  },
  {
    shortcutTitle: "Undo last point",
    keySecond: () => <ShortcutButton title="Z" type="filled" />,
    keyBtn: SHORTCUTS_KEYS.UNDO_LAST_POINT,
    id: "drawing_9",
  },
  {
    shortcutTitle: "While drawing lines...",
    subTitle: true,
    id: "drawing_10",
  },
  {
    shortcutTitle: "End line",
    keyFirst: () => <ShortcutButton title="Double click" type="outlined" />,
    keySecond: () => <ShortcutButton title="Enter" type="filled" />,
    keyBtn: SHORTCUTS_KEYS.END_LINE,
    divider: "or",
    id: "drawing_11",
  },
];

const SHORTCUTS_ITEMS_LIST_SELECTING: ListItem[] = [
  {
    shortcutTitle: "Selecting",
    title: true,
    id: "selecting_0",
  },
  {
    shortcutTitle: "Select tool",
    keySecond: () => <ShortcutButton title="Esc" type="filled" />,
    keyBtn: SHORTCUTS_KEYS.SELECT_TOOL,
    id: "selecting_1",
  },
  {
    shortcutTitle: "Multi-select",
    keyFirst: () => <ShortcutButton title="Shift" type="filled" />,
    keySecond: () => <ShortcutButton title="Click" type="outlined" />,
    keyBtn: SHORTCUTS_KEYS.MULTI_SELECT,
    id: "selecting_2",
  },
  {
    shortcutTitle: "Rectangle select (whole)",
    keySecond: () => (
      <ShortcutButton title="Drag left to right" type="outlined" />
    ),
    keyBtn: SHORTCUTS_KEYS.RECTANGLE_SELECT_WHOLE,
    id: "selecting_3",
  },
  {
    shortcutTitle: "Rectangle select (partial)",
    keySecond: () => (
      <ShortcutButton title="Drag right to left" type="outlined" />
    ),
    keyBtn: SHORTCUTS_KEYS.RECTANGLE_SELECT_PARTIAL,
    id: "selecting_4",
  },
  {
    shortcutTitle: "Copy",
    keyFirst: () => <ShortcutButton title="Ctrl" type="filled" />,
    keySecond: () => <ShortcutButton title="C" type="filled" />,
    keyBtn: SHORTCUTS_KEYS.COPY,
    id: "selecting_5",
  },
  {
    shortcutTitle: "Paste in place",
    keyFirst: () => <ShortcutButton title="Ctrl" type="filled" />,
    keySecond: () => <ShortcutButton title="V" type="filled" />,
    keyBtn: SHORTCUTS_KEYS.PASTE,
    id: "selecting_6",
  },
  {
    shortcutTitle: "Select all",
    keyFirst: () => <ShortcutButton title="Ctrl" type="filled" />,
    keySecond: () => <ShortcutButton title="A" type="filled" />,
    keyBtn: SHORTCUTS_KEYS.SELECT_ALL,
    id: "selecting_7",
  },
  {
    shortcutTitle: "While selected...",
    subTitle: true,
    id: "selecting_8",
  },
  {
    shortcutTitle: "Move multiple point",
    keyFirst: () => <ShortcutButton title="Ctrl" type="filled" />,
    keySecond: () => <ShortcutButton title="Drag" type="outlined" />,
    keyBtn: SHORTCUTS_KEYS.MOVE_MULTIPLE_POINT,
    id: "selecting_9",
  },
  {
    shortcutTitle: "Rotate 90 deg",
    keySecond: () => <ShortcutButton title="R" type="filled" />,
    keyBtn: SHORTCUTS_KEYS.ROTATE_90,
    id: "selecting_10",
  },
  {
    shortcutTitle: "Flip Vertical",
    keySecond: () => <ShortcutButton title="V" type="filled" />,
    keyBtn: SHORTCUTS_KEYS.FLIP_VERTICAL,
    id: "selecting_11",
  },
  {
    shortcutTitle: "Flip Horizontal",
    keySecond: () => <ShortcutButton title="H" type="filled" />,
    keyBtn: SHORTCUTS_KEYS.FLIP_HORIZONTAL,
    id: "selecting_12",
  },
  {
    shortcutTitle: "Duplicate in place",
    keyFirst: () => <ShortcutButton title="Ctrl" type="filled" />,
    keySecond: () => <ShortcutButton title="D" type="filled" />,
    keyBtn: SHORTCUTS_KEYS.DUPLICATE_IN_PLACE,
    id: "selecting_13",
  },
  {
    shortcutTitle: "Group selected items",
    keyFirst: () => <ShortcutButton title="Ctrl" type="filled" />,
    keySecond: () => <ShortcutButton title="G" type="filled" />,
    keyBtn: SHORTCUTS_KEYS.GROUP_SELECTED_ITEMS,
    id: "selecting_14",
  },
  {
    shortcutTitle: "Ungroup selected items",
    keyFirst: () => <ShortcutButton title="Ctrl" type="filled" />,
    keySecond: () => <ShortcutButton title="U" type="filled" />,
    keyBtn: SHORTCUTS_KEYS.UNGROUP_SELECTED_ITEMS,
    id: "selecting_15",
  },
];

export const QUALITY_PANEL_ITEMS_LIST: Array<{
  id: number;
  type: string;
  btnTitle: string;
  title: string;
}> = [
  {
    id: 0,
    type: "outlined",
    btnTitle: "Click",
    title: "Select items",
  },
  {
    id: 1,
    type: "outlined",
    btnTitle: "Double Click",
    title: "Draw with classification",
  },
  {
    id: 2,
    type: "outlined",
    btnTitle: "Drag",
    title: "Reorder",
  },
  {
    id: 3,
    type: "outlined",
    btnTitle: "Right Click",
    title: "Context menu",
  },
];

export const SHORTCUTS_LIST = [
  ...SHORTCUTS_ITEMS_LIST_GENERAL,
  ...SHORTCUTS_ITEMS_LIST_DRAWING,
  ...SHORTCUTS_ITEMS_LIST_SELECTING,
];
