import ShortcutButton from "./ShortcutButton";
import Classification from "./Classification";

import * as S from "../styles";

import { Para } from "sf/components/Styled";
import { QUALITY_PANEL_ITEMS_LIST } from "../list";

const QuantityPanel = () => {
  return (
    <S.QuantityPanelContainer>
      <div>
        <S.Title style={{ marginBottom: "19px" }} isTitle>
          Quantity Panel
        </S.Title>
        <S.TopContainer>
          <S.TitleContainer margin="0 0 20px 0">
            <ShortcutButton type="outlined" title="Click" />
            <S.SubTitle>Show / hide individual items</S.SubTitle>
          </S.TitleContainer>
          <S.QuantityPanelHeading>
            <S.TitleContainer>
              <ShortcutButton type="outlined" title="Click" />
              <S.SubTitle>Hide / show</S.SubTitle>
            </S.TitleContainer>
            <S.TitleContainer margin="0 0 0 0">
              <ShortcutButton margin="0 5px 0 0" type="filled" title="Ctrl" />
              <ShortcutButton type="outlined" title="Click" />
              <S.SubTitle>Hide all other</S.SubTitle>
            </S.TitleContainer>
          </S.QuantityPanelHeading>
          <S.TitleContainer margin="0 0 0 40px">
            <ShortcutButton type="outlined" title="Click" />
            <S.SubTitle>Draw with classification</S.SubTitle>
          </S.TitleContainer>
        </S.TopContainer>
        <Classification />
        <S.BottomContainer>
          {QUALITY_PANEL_ITEMS_LIST.map((item) => {
            return (
              <S.TitleBottomContainer key={item.id}>
                <ShortcutButton type={item.type} title={item.btnTitle} />
                <S.SubTitle>{item.title}</S.SubTitle>
              </S.TitleBottomContainer>
            );
          })}
        </S.BottomContainer>
      </div>
      <S.MacUserContainer>
        <Para style={{ color: "white" }}>For Mac Users</Para>
        <div style={{ display: "flex" }}>
          <ShortcutButton type="filled" title="Ctrl" />
          <S.Divider>=</S.Divider>
          <ShortcutButton type="filled" title="Command/Cmd ⌘" />
        </div>
      </S.MacUserContainer>
    </S.QuantityPanelContainer>
  );
};

export default QuantityPanel;
