import * as S from "../styles";

const ShortcutButton = ({ type = "outlined", title = "", margin = "" }) => {
  return (
    <S.ShortcutButtonContainer margin={margin} type={type}>
      {title}
    </S.ShortcutButtonContainer>
  );
}

export default ShortcutButton;
