import useShortcuts from "../hook";
import { SHORTCUTS_LIST } from "../list";

import * as S from "../styles";

const ShortcutsList = () => {
  const {
    shortcutsState: { pressedBtn },
  } = useShortcuts();

  return (
    <S.Wrapper>
      <S.ShortcutsListContainer>
        {SHORTCUTS_LIST.map((item) => {
          const componentBtnFirst = item.keyFirst ? item.keyFirst() : <></>;
          const componentBtnSecond = item.keySecond ? item.keySecond() : <></>;

          return (
            <S.ShortcutsListItem
              isTitle={item.title}
              key={item.id}
              isPressed={item.keyBtn?.some((elem) => elem === pressedBtn)}
            >
              <S.Title isTitle={item.title} isSubTitle={item.subTitle}>
                {item?.shortcutTitle}
              </S.Title>
              <S.ActionsContainer>
                <div style={{ marginRight: "3px" }}>{componentBtnFirst}</div>
                <div style={{ opacity: "0.5" }}>{item.divider}</div>
                <div style={{ marginLeft: "3px" }}>{componentBtnSecond}</div>
              </S.ActionsContainer>
            </S.ShortcutsListItem>
          );
        })}
      </S.ShortcutsListContainer>
    </S.Wrapper>
  );
}

export default ShortcutsList;
