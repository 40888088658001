import React from "react";
import { RecoilRoot } from "recoil";
import * as Sentry from "@sentry/react";
import { SnackbarProvider } from "notistack";
import { CssBaseline } from "@material-ui/core";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { ConfirmProvider } from "material-ui-confirm";
import { StylesProvider } from "@material-ui/core/styles";
import { QueryClient, QueryClientProvider } from "react-query";

import { ErrorBoundary } from "sf/components/ErrorBoundary";

import { lightTheme } from "src/lib/theme";
import { DataManager } from "src/UtilComponents/DataManager";
import ShortcutsGuide from "src/components/ShortcutsGuide";
import InputProvider from "src/modules/App/components/Input/Provider";

import AppRoutes from "./components/Routes";
import ThirdParty from "./components/ThirdParty";
import Permissions from "./components/Permissions";
import ReleaseReload from "./components/ReleaseReload";
import InvitationsManager from "./components/InvitationsManager";

import Notification from "../Notification";
import { AuthProvider } from "../Auth/context";
import UsageModal from "src/components/UsageModal";
import { CacheProvider } from "../../UtilComponents/ImageCache/context";
import { UploadProvider } from "../../UtilComponents/UploadManager/context";

const queryClient = new QueryClient();

export default function App() {
  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <BrowserRouter>
            <DataManager>
              <ThemeProvider theme={lightTheme}>
                <StylesProvider injectFirst>
                  <ConfirmProvider
                    defaultOptions={{
                      confirmationButtonProps: { autoFocus: true },
                    }}
                  >
                    <SnackbarProvider
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      maxSnack={5}
                    >
                      <InputProvider>
                        <CacheProvider>
                          <UploadProvider>
                            <Sentry.ErrorBoundary
                              fallback={ErrorBoundary(
                                "Togal.ai",
                                "help@togal.ai"
                              )}
                            >
                              <CssBaseline />
                              <AppRoutes />
                              <Notification />
                              <ThirdParty />
                              <ReleaseReload />
                              <Permissions />
                              <UsageModal />
                              <ShortcutsGuide />
                              <InvitationsManager />
                            </Sentry.ErrorBoundary>
                          </UploadProvider>
                        </CacheProvider>
                      </InputProvider>
                    </SnackbarProvider>
                  </ConfirmProvider>
                </StylesProvider>
              </ThemeProvider>
            </DataManager>
          </BrowserRouter>
        </AuthProvider>
      </QueryClientProvider>
    </RecoilRoot>
  );
}
