import styled, { keyframes } from "styled-components";

import { ReactComponent as ArrowIcon } from "src/svg/arrow.svg";
import { ReactComponent as CancelIcon } from "src/svg/editor/cancel.svg";

import { CSS } from "sf/consts/style";
import { Flex } from "sf/components/Styled";
import { Button } from "sf/components/Button";

export const Container = styled.div`
  position: absolute;
  z-index: ${CSS.Z_INDEX_MAX};

  bottom: 0;
  width: 100vw;
  height: 60vh;
  max-height: 800px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 40px;

  @media screen and (max-height: 800px) {
    padding-bottom: 10px;
  }
`;

const backgroundAnimation = keyframes`
  from {
    clip-path: inset(100% 0 0% 0);
  }

  to {
    clip-path: inset(0 0 0 0);
  }
`;

export const Background = styled(Container)`
  background: ${CSS.DARKGREY};

  will-change: clip-path;
  clip-path: inset(50% 0 0% 0);
  animation: ${backgroundAnimation} 0.6s ease forwards;
`;

export const CancelIconContainer = styled(CancelIcon)`
  position: absolute;
  right: 30px;
  top: 30px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  z-index: 1000000;
  cursor: pointer;

  path {
    stroke: ${CSS.WHITE} !important;
  }
`;

export const ShortcutsContainer = styled.div`
  width: 80%;
  height: 94%;
  z-index: 100000;
  display: flex;
  flex-direction: row;
  justify-content: center;

  will-change: clip-path;
  clip-path: inset(50% 0 50% 0);
  animation: ${backgroundAnimation} 0.3s ease forwards;

  @media screen and (max-height: 1300px) {
    overflow: auto;
  }
  @media screen and (max-width: 1640px) {
    width: 100%;
  }
`;

export const ShortcutsListContainer = styled.div`
  max-width: 375px;
  width: 100%;
  height: 96%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  column-gap: 90px;
  @media screen and (max-width: 1640px) {
    column-gap: 40px;
  }
`;

export const Wrapper = styled.div`
  width: 840px;
  height: 700px;
  overflow: hidden;
  @media screen and (max-width: 1640px) {
    width: 800px;
  }
`;

export const ShortcutsListItem = styled.div<{
  isPressed: boolean;
  isTitle: boolean;
}>`
  width: inherit;
  height: 28px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  color: ${CSS.WHITE};
  margin: 8px 0 8px 0;
  margin-bottom: ${(props: any) => (props.isTitle ? "11px" : "inherit")};
  border: 1px solid
    ${(props: any) => (props.isPressed ? `${CSS.WHITE}` : "transparent")};
  border-radius: ${CSS.BORDER_RADIUS};
`;

export const ActionsContainer = styled(Flex)`
  justify-content: flex-end;
`;

export const Title = styled.div<{
  isTitle?: boolean;
  isSubTitle?: boolean;
}>`
  display: flex;
  align-items: center;
  font-size: ${(props: any) => (props.isTitle ? "20px" : "14px")};
  font-weight: ${(props: any) => (props.isTitle ? "700" : "500")};
  opacity: ${(props: any) => (props.isSubTitle ? "0.5" : "1")};
  width: -webkit-fill-available;
  color: ${CSS.WHITE};
`;

export const QuantityPanelContainer = styled.div`
  max-width: 375px;
  width: 100%;
  height: 635px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-right: 90px;
  justify-content: space-between;

  @media screen and (max-width: 1640px) {
    margin-right: 40px;
  }
`;

export const TitleContainer = styled.div<{
  margin?: string;
  isBottom?: boolean;
  isTop?: boolean;
}>`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin: ${(props: any) => props.margin && props.margin};
  position: relative;

  div:first-of-type {
    ::after {
      content: "";
      position: absolute;
      height: 169px;
      width: 1px;
      background-color: ${CSS.MAIN2};
      top: 24px;
      left: 5px;
    }

    ::before {
      content: "";
      position: absolute;
      height: 1px;
      width: 12px;
      background-color: ${CSS.MAIN2};
      top: 192px;
      left: 0px;
    }
  }
`;

export const TitleBottomContainer = styled.div<{
  margin?: string;
  isBottom?: boolean;
  isTop?: boolean;
}>`
  display: flex;
  align-items: center;
  margin: ${(props: any) => props.margin && props.margin};
  margin-bottom: 10px;

  ::before {
    content: "";
    position: absolute;
    height: 150px;
    width: 1px;
    background-color: ${CSS.MAIN2};
    top: -39px;
    left: 11px;
    z-index: -1;
  }
`;

export const QuantityPanelHeading = styled.div`
  margin: 0 0 20px 20px;
  position: relative;

  ::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 12px;
    background-color: ${CSS.MAIN2};
    top: 147px;
    left: 1px;
  }
  ::after {
    content: "";
    position: absolute;
    height: 1px;
    width: 12px;
    background-color: ${CSS.MAIN2};
    top: 147px;
    left: 21px;
  }
`;

export const SubTitle = styled("p")`
  color: ${CSS.WHITE};
  font-weight: 400;
  font-size: 14px;
  margin: 0 0 0 10px;
`;

export const TopContainer = styled.div`
  padding-bottom: 40px;
  margin-bottom: 10px;
  overflow: hidden;
`;

export const BottomContainer = styled.div`
  margin-left: 65px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  position: relative;

  ::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 313px;
    background-color: ${CSS.MAIN2};
    top: -40px;
    left: -3px;
  }
`;

export const ClassificationContainer = styled.div`
  background-color: ${CSS.WHITE};
  height: 32px;
  display: flex;
  align-items: center;
  position: relative;
`;

export const ExpandButton = styled(Button)`
  min-width: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  transform: rotate(-90deg);
  top: 1px;
  left: 8px;

  svg {
    path {
      stroke: ${CSS.BLACK};
    }
  }
`;

export const StyledChevron = styled(ArrowIcon)`
  height: 12px;
`;

export const MacUserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Divider = styled.div`
  color: ${CSS.WHITE};
  display: flex;
  align-items: center;
  margin: 0 5px 0 5px;
`;

export const ShortcutButtonContainer = styled.div<{
  margin?: string;
  type?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  padding: 0 10px;
  width: max-content;
  font-weight: 700;
  font-size: 14px;
  position: relative;
  margin: ${(props) => props.margin && props.margin};

  border-radius: ${CSS.BORDER_RADIUS};
  border: 1px solid ${CSS.MAIN2};

  background-color: ${(props) =>
    props.type === "outlined" ? CSS.DARKGREY : CSS.MAIN2};
  color: ${(props) => (props.type === "outlined" ? CSS.MAIN2 : CSS.DARKGREY)};
`;

