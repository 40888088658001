import QuantityPanel from "./Components/QuantityPanel";
import ShortcutsList from "./Components/ShortcutsList";

import useShortcuts from "./hook";

import * as S from "./styles";

const ShortcutsGuide = () => {
  const { close, visible } = useShortcuts();

  if (!visible) return null;

  return (
    <S.Container>
      <S.CancelIconContainer onClick={close} />
      <S.Background>
        <S.ShortcutsContainer>
          <QuantityPanel />
          <ShortcutsList />
        </S.ShortcutsContainer>
      </S.Background>
    </S.Container>
  );
};

export default ShortcutsGuide;
